import { Tasks } from "../../../interfaces/Tasks";
import "./style.css";

const SlurmTaskTable = ({
  data,
  date,
}: {
  data: Tasks[];
  date: { month: number | undefined; year: number | undefined };
}) => {
  return (
    <div
      id="task_list"
      className="fullcard flex  card p-[20px] rounded-[20px] flex-col gap-y-4 "
    >
      <div className="flex justify-between">
        <span className="text-[400] text-[1.75rem] leading-[1.334]">
          Slurm
        </span>
        <span className="text-[400] text-[1.25rem]">
          {date.month && date.year ? `${date.month}/${date.year}` : "Current"}
        </span>
      </div>
      <div className="max-h-[70vh] overflow-y-scroll">
        <table id="task_Id" className="w-full ">
          <thead>
            <tr>
              <th>Job Index</th>
              <th>Job Name</th>
              <th>Scheduler</th>
              <th>Cluster</th>
              <th>Work Directory</th>
              <th>Time Used (sec)</th>
              <th>Period</th>
              <th>allocated resources</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr>
                <td>{item.jobInx}</td>
                <td>{item.jobName}</td>
                <td>slurm</td>
                <td>apex</td>
                <td>{item.workDir}</td>
                <td align="right">{item.timeUsage}</td>
                <td>
                  <table className="w-full">
                    <tr>
                      <td>Start: {item.period.start}</td>
                    </tr>
                    <tr>
                      <td>End: {item.period.end}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table className="w-full">
                    <tr>
                      <td>CPU: {item.resAlloc.cpu}</td>
                      <td>GPU: {item.resAlloc.gpu}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SlurmTaskTable;
