import { dateReadableFormat } from "src/utils/date-format";
import { GliderTask, Tasks } from "../../../interfaces/Tasks";
import "./style.css";

const KubeTasksTable = ({
  data,
  date,
}: {
  data: GliderTask[];
  date: { month: number | undefined; year: number | undefined };
}) => {
  return (
    <div
      id="task_list"
      className="fullcard flex  card p-[20px] rounded-[20px] flex-col gap-y-4 "
    >
      <div className="flex justify-between">
        <span className="text-[400] text-[1.75rem] leading-[1.334]">
          Glider
        </span>
        <span className="text-[400] text-[1.25rem]">
          {date.month && date.year ? `${date.month}/${date.year}` : "Current"}
        </span>
      </div>
      <div className="max-h-[70vh] overflow-y-scroll">
        <table id="task_Id" className="w-full ">
          <thead>
            <tr>
              <th>Name</th>
              <th>Namespace</th>
              <th>Project</th>
              <th>Status</th>
              <th>Time Used (sec)</th>
              <th>Period</th>
              <th>allocated resources</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => {
              let resource = JSON.parse(item.resAlloc);

              return (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.namespace}</td>
                  <td>{item.project}</td>
                  <td>{item.status}</td>
                  <td align="right">
                    {item.status === "Succeeded"
                      ? item.timeEnd - item.timeStart
                      : "-"}
                  </td>
                  <td>
                    <table className="w-full">
                      <tr>
                        <td>
                          Start:{" "}
                          {item.timeStart > 0
                            ? dateReadableFormat(
                                new Date(item!.timeStart * 1000).toISOString()
                              )
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          End:{" "}
                          {item.timeEnd > 0
                            ? dateReadableFormat(
                                new Date(item!.timeEnd * 1000).toISOString()
                              )
                            : "-"}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table className="w-full">
                      <tr>
                        <td>CPU: {resource.cpu?resource.cpu:'-'}</td>
                        <td>GPU: {resource.gpu?resource.cpu:'-'}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default KubeTasksTable;
