import "./App.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Navbar from "./components/Navbar/Navbar";
import ProtectedRoute from "./authentication/protectedRoute";
import PageNotFound from "./pages/Error/404";
import BindAccount from "./pages/Account/BindAccount";
import RequestAccount from "./pages/Account/RequestAccount";
import SetAccount from "./pages/Account/SetUpAccount";
import Callback from "./pages/Callback";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import ServerErr from "./pages/Error/500";
import AdminRoute from "./authentication/adminRoute";
import UserProject from "./pages/Projects";
import ProjectResource from "./pages/ProjectResource";
import Setting from "./pages/Settings/Setting";
import AdminProject from "./pages/Admin/Project";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "bg-red-500 mt-5",
          duration: 1800,
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
      <Navbar />
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/">
            <Route element={<ProtectedRoute />}>
              <Route index element={<Dashboard />}></Route>
              <Route path="projects" element={<UserProject />}></Route>
              <Route path="setting" element={<Setting />}></Route>
              <Route
                path="project/:name/:id"
                element={<ProjectResource />}
              ></Route>
              <Route element={<AdminRoute />}>
                <Route path="admin" element={<AdminProject />}></Route>
              </Route>
            </Route>
            <Route path="set-account" Component={SetAccount} />
            {/* <Route path="test" Component={Codeserver} /> */}
            <Route path="bind-account" Component={BindAccount} />
            <Route path="request-account" Component={RequestAccount} />
            <Route path="500" Component={ServerErr} />

            <Route path="*" Component={PageNotFound} />
          </Route>
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Callback />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
