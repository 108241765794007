import React, { useEffect, useRef, useState } from "react";
import SlurmTaskTable from "./customs/SlurmTasksTable";
import { DataUsage } from "src/interfaces/UsageData";
import KubeTasksTable from "./customs/KubeTasksTable";

const TaskListTab = ({
  data,
  month,
  year,
}: {
  data: DataUsage;
  month: number;
  year: number;
}) => {
  const tabs = {
    default: "Glider",
    value: [
      {
        name: "Glider",
        element: (
          <KubeTasksTable
            key={"Glider"}
            data={data.KubeTaskList}
            date={{ year, month }}
          />
        ),
      },
      {
        name: "Slurm",
        element: (
          <SlurmTaskTable
            key={"Slurm"}
            data={data.TaskList}
            date={{ year, month }}
          />
        ),
      },
    ],
  };

  const [currentTab, setcurrentTab] = useState(tabs.default);
  return (
    <div className="fullcard flex  card p-[20px] rounded-[20px] flex-col gap-y-4 ">
      <div className="sm:hidden border-b border-white pb-2">
        <label htmlFor="Tab" className="sr-only">
          Tab
        </label>

        <select
          id="Tab"
          className="w-full rounded-md  bg-transparent border-gray-200"
        >
          {tabs.value.map((tab) => (
            <option id={tab.name} className="text-black">
              {tab.name}
            </option>
          ))}
        </select>
      </div>

      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex gap-3 " aria-label="Tabs">
            {tabs.value.map((tab) => (
              <button
                id={tab.name}
                onClick={() => {
                  setcurrentTab(tab.name);
                }}
                className={
                  "shrink-0 border-b-2 border-transparent px-2 pb-3 pt-1 text-sm font-medium text-white hover:border-gray-100 hover:text-gray-400 rounded-t-md" +
                  (tab.name === currentTab
                    ? " border-b-2 border-white bg-white bg-opacity-15"
                    : "")
                }
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      {tabs.value.find((tab) => tab.name === currentTab)?.element}
    </div>
  );
};

export default TaskListTab;
