import axios from "axios";
import React, { useState } from "react";
import { CreateTask } from "src/api/project";
import { useUserContext } from "src/contexts/ApexUserContext";
import { UserContextType } from "src/types/userContextType";
import { error } from "console";
import toast from "react-hot-toast";
const TaskModal = ({
  isOpen,
  setcreateTaskModal,
  projectId,
  userId,
  loaddata,
  loading,
  setLoading,
  existName,
}: {
  isOpen: boolean;
  setcreateTaskModal: (v: boolean) => void;
  projectId: string;
  userId: string;
  loaddata: () => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  existName: string[];
}) => {
  let errMessage = {
    regex:
      "Name must be lowercase alphanumeric characters or '-', start with a letter, and end with a letter or digit.",
    nameExist: "Task name already exist.",
  };
  const nameRegex = /^[a-z]([-a-z0-9]*[a-z0-9])?$/;
  const [jobVal, setjobVal] = useState("");
  const [inpinpVal, setinpVal] = useState("");
  const [passVal, setpassVal] = useState("");
  const [falseVal, setfalseVal] = useState({ error: false, msg: "" });

  if (isOpen) {
    return (
      <div className="cover absolute  h-full w-full bg-black  bg-opacity-80 mb-3 rounded flex justify-center items-center">
        <div
          className={`flex flex-col relative rounded px-12 py-8 max-w-[450px] w-[50%] min-h-[60%] bg-[#0e1219] gap-y-3 `}
        >
          <div
            className="text-2xl text-white absolute right-[20px] top-[10px] cursor-pointer"
            onClick={() => {
              setfalseVal({ error: false, msg: "" });
              setcreateTaskModal(false);
            }}
          >
            x
          </div>
          <h1 className="text-2xl font-semibold">Select Your Task </h1>

          <div className="flex px- gap-x-2">
            <div className="cursor-pointer">
              <div
                id="codeserver"
                className="h-[60px] w-[60px] p-2 rounded-md  border-2 hover:bg-white "
                onClick={(e) => {
                  setjobVal("codeserver");
                  document.getElementById("codeserver")!.style.backgroundColor =
                    "white";
                }}
              >
                <img
                  className="h-full w-full"
                  src="https://artifacthub.io/image/82339e82-7d27-4579-980e-c101329b7aa8@1x"
                  alt=""
                />
              </div>
              <p className="text-center w-full text-[12px]">Code Server</p>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col gap-y-1">
              <label htmlFor="" className="text-lg font-semibold">
                Task name
              </label>
              <input
                type="text"
                className="bg-transparent border rounded-md p-1 px-2"
                onChange={(e) => {
                  setinpVal(e.target.value);
                }}
                required
              />
              <p className="text-sm  ">e.g. abc, abc123, or abc-123.</p>
              {falseVal && (
                <p className="text-sm text-red-500 ">{falseVal?.msg}</p>
              )}
            </div>
            {jobVal && jobVal === "codeserver" && (
              <div className="flex flex-col gap-y-1">
                <label htmlFor="" className="text-lg font-semibold">
                  Password
                </label>
                <input
                  type="password"
                  className="bg-transparent border rounded-md p-1 px-2"
                  onChange={(e) => {
                    setpassVal(e.target.value);
                  }}
                  required
                />
                <p className="text-sm  "></p>
              </div>
            )}
          </div>
          <div className="flex-grow w-full h-full flex flex-col-reverse">
            <div className="w-full disabled:opacity-40 flex flex-row-reverse ">
              <button
                disabled={inpinpVal === "" || jobVal === "" || passVal === ""}
                className="nav-btn "
                onClick={async () => {
                  if (!nameRegex.test(inpinpVal)) {
                    setfalseVal({ error: true, msg: errMessage.regex });
                  } else if (existName.includes(inpinpVal)) {
                    setfalseVal({ error: true, msg: errMessage.nameExist });
                  } else {
                    setfalseVal({ error: false, msg: "" });
                    // setLoading(true);
                    toast.promise(
                      CreateTask({
                        owner_id: userId,
                        project_id: projectId,
                        task_type: jobVal,
                        task_name: inpinpVal,
                        password: passVal,
                      }),
                      {
                        loading: "Task Creating...",
                        success: () => {
                          loaddata();
                          setcreateTaskModal(false);
                          return <b>Task successfully created.</b>;
                        },
                        error: <b>Task create failed. Please try again.</b>,
                      }
                    );

                    // await CreateTask({
                    //   owner_id: userId,
                    //   project_id: projectId,
                    //   task_type: jobVal,
                    //   task_name: inpinpVal,
                    //   password: passVal,
                    // }).then((res) => {
                    //   if (res) {
                    //     loaddata();
                    //     setcreateTaskModal(false);
                    //   }else {
                    //     toast.error("Failed to create task.");
                    //     setLoading(false);
                    //   }
                    // });
                  }
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <></>;
};

export default TaskModal;
