import { CreateTask } from "./project";
import axios from "axios";
const service_endpoint = process.env.REACT_APP_API_BASE_URL
console.log("start with endpoint:", service_endpoint);


const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 15000
})
export const setReqToken = (accessToken: string) => {
    axiosClient.interceptors.request.use(request => {
        if (accessToken) {
            request.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return request
    })
}
export { axiosClient }

export const API = {
    host: service_endpoint,
    endpoint: {
        userUsages: "/usage/current",
        userUsagesPermonth: "/usage/month",
        checkUser: "/user/check-apex-account",
        requestUser: "/user/request-apex-account",
        mapUser: "/user/manual-map-apex-account",
        summary: "/analyze/summary",
        getUserProject: "/service-gateway/projects",
        getProjectById: "/service-gateway/project",
        getProjectNamespaces: "/service-gateway/namespaces",
        requestNamespace: "/service-gateway/namespace",
        createDefaultProject: "/service-gateway/project/init/default",
        createProject: "/service-gateway/project/init",
        deleteProject: "/service-gateway/project", 
        getUserKubeConfig: "/service-gateway/user/kube-config",
        createTask: "/service-gateway/task",
        deleteTask: "/service-gateway/task", 
        createTasks: "/service-gateway/tasks",
        deleteTasks: "/service-gateway/tasks"
    }
}
export let createEndpoint = (path: string): string => {
    return path
}

