import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteTask,
  GetProjectById,
  GetProjectNamespaces,
  RequestNamespace,
} from "src/api/project";
import { getUserKubeConfigByNamespace } from "src/api/user";
import { UserContextType } from "src/types/userContextType";
import { useUserContext } from "src/contexts/ApexUserContext";
import { set } from "date-fns";
import Loading from "src/components/Loading";
import { dateReadableFormat } from "src/utils/date-format";
import TaskModal from "src/components/ProjectResource/TaskModal";
import toast from "react-hot-toast";

interface NamespaceResponse {
  error: boolean;
  namespaces: Namespace[];
}

export interface Namespace {
  id: string;
  created_at: string;
  updated_at: string;
  project_id: string;
  owner_id: string;
  cluster_id: string;
  namespace: string;
  status: string;
}
export interface Task {
  id: string;
  created_at: string;
  updated_at: string;
  task_name: string;
  user_id: string;
  project_id: string;
  time_start: number;
  time_end: number;
  status: string;
  res_alloc: string;
}
interface Cluster {
  id: string;
  name: string;
  status: string;
  organization: string;
}
interface ProjectDetails {
  namespaces: any;
  id: string;
  created_at: string;
  updated_at: string;
  owner_id: string;
  name: string;
  default_namespace: string;
  user_name: string;
  tasks: any;
}
let clusters: Cluster[] = [
  {
    id: "5016514e-04b2-47ae-a472-414a4ec17d54",
    name: "Apex",
    status: "Available",
    organization: "CMKL",
  },
];
type TaskLimit = {
  codeserver: number;
};

const ProjectResource = () => {
  // const taskLimit: TaskLimit = {
  //   codeserver: 1,
  // };
  // let [taskRemain, setTaskRemain] = useState<TaskLimit>(taskLimit);
  let { user } = useUserContext() as UserContextType;
  let [btnText, setBtnText] = useState(
    "Get Kubernetes config for this namespace"
  );
  let [createTaskModal, setcreateTaskModal] = useState(false);
  let { instance } = useMsal();
  let navigate = useNavigate();
  const [data, setdata] = useState<ProjectDetails>();
  const [userKubeConfig, setUserKubeConfig] = useState<any>(null);
  let { id, name } = useParams();
  const [loading, setLoading] = useState(true);
  let activeAccount = instance.getActiveAccount();
  let loaddata = () => {
    try {
      GetProjectById(id!).then((res) => {
        if (res) {
          setdata(res?.data!.project);
          setLoading(false);
        } else navigate("/500", { replace: true });
      });
    } catch (error) {}
  };
  useEffect(() => {
    loaddata();
    // let intId: NodeJS.Timer;
    // if (!data) {
    //   intId = setInterval(loaddata, 5000);
    // }
    // return () => clearInterval(intId);
  }, []);
  // useEffect(() => {
  //   if (data?.tasks) {
  //     let codeserverTask = Array.from(data.tasks).length;
  //     setTaskRemain({codeserver:codeserverTask})
  //   }
  //   // data?.tasks
  // }, [data]);
  useEffect(() => {
    if (userKubeConfig?.kubeconfigYAML) {
      navigator.clipboard.writeText(userKubeConfig!.kubeconfigYAML);
    }
  }, [userKubeConfig]);
  if (loading) {
    return <Loading isActive={loading} />;
  }
  <Loading isActive={false} />;
  return (
    <div className="layout">
      <TaskModal
        userId={user?.ad_id as string}
        isOpen={createTaskModal}
        setcreateTaskModal={setcreateTaskModal}
        projectId={id as string}
        loaddata={loaddata}
        loading={loading}
        setLoading={(val: boolean) => setLoading(val)}
        existName={data?.tasks
          .filter((e: any) => e.status === "Running" || e.status === "Pending")
          .map((e: any) => e.task_name)}
      />
      <div className="flex flex-col gap-y-5 w-full pt-5">
        <h1 className="text-4xl font-bold">Project: {name}</h1>
        <div className="mt-6 text-2xl flex flex-col gap-y-3">
          <div>Namespace: {data?.default_namespace}</div>
          <div>Create At : {dateReadableFormat(data!.created_at)}</div>
        </div>

        <button
          className="nav-btn"
          disabled={!activeAccount}
          onClick={() => {
            if (!userKubeConfig) {
              getUserKubeConfigByNamespace(
                user?.ipa_name as string,
                data?.default_namespace!
              ).then((res) => {
                setBtnText("Copied config to your clipboard");

                setUserKubeConfig(res);
              });
            } else {
              if (userKubeConfig?.kubeconfigYAML) {
                navigator.clipboard.writeText(userKubeConfig!.kubeconfigYAML);
              }
            }
          }}
        >
          {btnText}
        </button>
        {/* <RequestResourceModal
          projectId={id!}
          userId={instance.getActiveAccount()?.localAccountId!}
          namespaces={data?.namespaces}
          loaddata={loaddata}
        /> */}
        <ProjectTask
          data={data?.tasks}
          setcreateTaskModal={setcreateTaskModal}
          loaddata={() => {
            loaddata();
          }}
          loading={loading}
          setLoading={(val: boolean) => setLoading(val)}
        />
      </div>
    </div>
  );
};

export default ProjectResource;
let RequestResourceModal = ({
  projectId,
  userId,
  namespaces,
  loaddata,
}: {
  projectId: string;
  userId: string;
  namespaces: Namespace[];
  loaddata: () => void;
}) => {
  let clustersData: Cluster[] = [
    {
      id: "5016514e-04b2-47ae-a472-414a4ec17d54",
      name: "Apex",
      status: "Available",
      organization: "CMKL",
    },
  ];
  let [clusters, setCluster] = useState<Cluster[]>(clustersData);
  let getClusterName = (id: string) => {
    return clusters.find((item) => item.id === id)?.name;
  };
  // useEffect(() => {
  //   let requestedNs  = namespaces.map(ns=>ns.cluster_id)
  //   setCluster(clusters.filter((item) => !requestedNs.includes(item.id)));
  // }, [namespaces]);

  return (
    <>
      <div className="w-full ">
        <div className="flex justify-between pr-3 mb-1 items-center">
          <h1 className="text-2xl font-bold  ">Cluster resource</h1>
        </div>
        <table id="task_Id" className="w-full ">
          <thead>
            <tr>
              <th>Cluster</th>
              <th>Organization</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {clusters?.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.organization}</td>
                  <td>{item.status}</td>
                  <td>
                    <div className="flex gap-x-2 justify-center">
                      <button
                        disabled={namespaces
                          .map((ns) => ns.cluster_id)
                          .includes(item.id)}
                        onClick={() => {
                          let reqBody = {
                            owner_id: userId,
                            project_id: projectId,
                            cluster_id: item.id,
                          };
                          RequestNamespace(reqBody).then((res) => {
                            if (res) {
                              loaddata();
                            }
                          });
                        }}
                        className="nav-btn"
                      >
                        Request
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="w-full ">
        <div className="flex justify-between pr-3 mb-1 items-center">
          <h1 className="text-2xl font-bold  ">Your available cluster</h1>
        </div>
        <table id="task_Id" className="w-full ">
          <thead>
            <tr>
              <th>Cluster</th>
              <th>Namespace</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {namespaces?.map((item: Namespace) => (
              <tr
                key={item.id}
                // onClick={() => {
                //   navigate("1", { replace: false });
                // }}
              >
                <td>{getClusterName(item.cluster_id)}</td>
                <td>{item.namespace}</td>
                <td>{item.status}</td>
                <td>
                  <div className="flex gap-x-2 justify-center">
                    <button disabled className="nav-btn">
                      Edit
                    </button>
                    <button disabled className="nav-btn">
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
let ProjectTask = ({
  data,
  setcreateTaskModal,
  loaddata,
  loading,
  setLoading,
}: {
  data: Task[];
  setcreateTaskModal: (v: boolean) => void;
  loaddata: () => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}) => {
  return (
    <div>
      <div className="w-full ">
        <div className="flex justify-between pr-3 mb-1 items-center">
          <h1 className="text-2xl font-bold  ">Your Task</h1>
          <button
            onClick={() => {
              setcreateTaskModal(true);
            }}
            className="nav-btn w-20 "
          >
            New
          </button>
        </div>
        <table id="task_Id" className="w-full ">
          <thead>
            <tr>
              <th>Task name</th>
              <th>Status</th>
              <th>Create At</th>
              <th>Start At</th>
              <th>End At</th>
              <th>Resource</th>
              <th>Menu</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((task: Task) => {
              let resource = JSON.parse(task.res_alloc);

              return (
                <tr key={task.id}>
                  <td>{task.task_name}</td>
                  <td>{task.status}</td>
                  <td>{dateReadableFormat(task.created_at)}</td>
                  <td>
                    {task.time_start > 0
                      ? dateReadableFormat(
                          new Date(task!.time_start * 1000).toISOString()
                        )
                      : "-"}
                  </td>
                  <td>
                    {task.time_end > 0
                      ? dateReadableFormat(
                          new Date(task!.time_end * 1000).toISOString()
                        )
                      : "-"}
                  </td>
                  <td>
                    {task.status === "Pending" ? (
                      <div className="flex flex-col justify-center items-center">
                        <div role="status" className="flex gap-x-1">
                          <svg
                            aria-hidden="true"
                            className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="text-[#f5f5f7] text-xs ">
                            Waiting ~1min
                          </span>
                        </div>
                      </div>
                    ) : (
                      <button
                        disabled={
                          !(
                            task.status === "Running" ||
                            task.status === "Pending"
                          )
                        }
                      >
                        <a
                          className="cursor-pointer !p-[5px] nav-btn !text-[16px]"
                          href={`https://${resource?.url}`}
                          target="_blank"
                        >
                          code server
                        </a>
                      </button>
                    )}
                  </td>
                  <td>
                    <button
                      disabled={!(task.status === "Running")}
                      className="cursor-pointer !p-[5px] nav-btn"
                      onClick={() => {
                        // setLoading(true);
                        toast.promise(DeleteTask({ id: task.id }), {
                          loading: "Stopping...",
                          success: () => {
                            loaddata();
                            return <b>Task successfully stopped.</b>;
                          },
                          error: (
                            <b>Task stop failed. Please try again.</b>
                          ),
                        });
                        // DeleteTask({ id: task.id }).then((res) => {
                        //   if (res) {
                        //     loaddata();
                        //   } else {
                        //     setLoading(false);
                        //     toast.error("Failed to delete task");
                        //   }
                        // });
                      }}
                    >
                      Stop
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
