const now = new Date();
const effectYear = 2023;
const historyYear = Array.from(
  { length: now.getFullYear() - effectYear + 1 },
  (v, k) => effectYear + k
);

const monthSelections = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const DateSelection = historyYear?.map((year) => {
  if (year === now.getFullYear()) {
    return { year, monthOfYear: monthSelections.slice(0, now.getMonth()+1) };
  }
  return {
    year,
    monthOfYear: monthSelections.slice(0, 12),
  };
});

let toggleShow = (id: string) => {
  document.getElementById(id)!.style.display = "block";
};
let toggleHide = (id: string) => {
  document.getElementById(id)!.style.display = "none";
};

interface UsageSelectProps {
  inspectState: boolean;
  setInspectState: (val: boolean) => void;
  setSelectedYear: (val: number | undefined) => void;
  setSelectedMonth: (val: number | undefined) => void;
  selectedYear: number | undefined;
}
const UsageSelect = ({
  setInspectState,
  inspectState,
  setSelectedYear,
  setSelectedMonth,
  selectedYear,
}: UsageSelectProps) => {
  return (
    <div className="flex flex-col gap-y-1 ">
      <div
        className="flex  justify-center overflow-hidden  rounded-full border cursor-pointer"
        onClick={() => {
          setInspectState(!inspectState);
        }}
      >
        <div
          className={
            `text-center w-1/2 text-lg` + (!inspectState ? " active" : "")
          }
        >
          Current
        </div>
        <div
          className={
            `text-center w-1/2 text-lg` + (inspectState ? " active" : "")
          }
        >
          Inspect
        </div>
      </div>
      <div className="flex gap-x-2 mt-1 justify-center disabled:opacity-20">
        <div
          onClick={() => {
            toggleShow("yearSelector");
          }}
        >
          <button
            className="dropdown disabled:opacity-50"
            disabled={!inspectState}
          >
            <div className="select">
              <span id="yearSpan">Year</span>
              <i className="fa fa-chevron-left"></i>
            </div>
            <input type="hidden" name="year" />
            <ul
              onMouseLeave={() => {
                toggleHide("yearSelector");
              }}
              id="yearSelector"
              className="dropdown-menu"
            >
              {DateSelection?.map((e) => (
                <li
                  key={e.year}
                  onClick={() => {
                    document.getElementById("yearSpan")!.innerText =
                      e.year.toString();
                    setSelectedYear(e.year);
                    setSelectedMonth(undefined);
                    document.getElementById("monthSpan")!.innerText = "Month";
                  }}
                >
                  {e.year}
                </li>
              ))}
            </ul>
          </button>
        </div>
        <div
          onClick={() => {
            toggleShow("monthSelector");
          }}
        >
          <button
            className="dropdown disabled:opacity-50"
            disabled={!selectedYear}
          >
            <div className="select">
              <span id="monthSpan">Month</span>
              <i className="fa fa-chevron-left"></i>
            </div>
            <input type="hidden" name="month" />
            <ul
              onMouseLeave={() => {
                toggleHide("monthSelector");
              }}
              id="monthSelector"
              className="dropdown-menu"
            >
              {selectedYear &&
                DateSelection.filter(
                  (e) => e.year === selectedYear
                )[0]?.monthOfYear?.map((month, i) => (
                  <li
                    key={i}
                    onClick={() => {
                      setSelectedMonth(i + 1);
                      document.getElementById("monthSpan")!.innerText = month;
                    }}
                  >
                    {month}
                  </li>
                ))}
            </ul>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsageSelect;
