import { axiosClient as axios } from "../API"
import { API, createEndpoint } from "../API"
import { DataUsage, UserUsageQueryParams, UserUsageMonthQueryParams } from "../../interfaces/UsageData"

let { endpoint } = API
let getResourceUsageCurrentMouth = async (params:UserUsageQueryParams): Promise<DataUsage> => {
    try {
        let res = await axios.get(createEndpoint(endpoint.userUsages), {
            params
        })
        let data = await res.data
        return data
    } catch (error) {
        console.log(error);
    }
    return {
        CPUCost: 0,
        CPUTime: 0,
        GPUCost: 0,
        GPUTime: 0,
        Storage: 0,
        StorageCost: 0,
        Success: false,
        TotalTask: 0,
        TaskList: [],
        KubeTaskList: []
    }
}
let getResourceUsageThatMouth = async (params:UserUsageMonthQueryParams): Promise<DataUsage> => {
    try {
        let res = await axios.get(createEndpoint(endpoint.userUsagesPermonth), {
            params
        })
        let data = await res.data

        return data
    } catch (error) {
        console.log(error);
    }
    return {
        CPUCost: 0,
        CPUTime: 0,
        GPUCost: 0,
        GPUTime: 0,
        Storage: 0,
        StorageCost: 0,
        Success: false,
        TotalTask: 0,
        TaskList: [],
        KubeTaskList: []
    }
}
export { getResourceUsageCurrentMouth, getResourceUsageThatMouth } 